<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <public-filter
          :action-methods="searchData"
          :action-filter="getFilterData"
          :reset-filter="resetSearch"
          :download-excel="downloadExcel"
        />
      </b-card>
    </b-col>
    <b-col cols="12">
      <div
        v-if="saleOrderFormLoading"
        class="text-primary"
      >
        <loading />
      </div>
      <b-card no-body>
        <b-table
          class="font-small-3"
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
          hover
          small
        >
          <template #cell(order_number)="data">
            <template v-if="data.item.status === '3'">
              <b-button
                variant="flat-primary"
                size="sm"
                :to="'/sale_orderforms/view/' + data.item.order_number"
                :disabled="data.item.id_com_user !== userData.id"
              >
                {{ data.item.order_number }}
              </b-button>
            </template>
            <template v-else-if="data.item.status === '0'">
              <b-button
                variant="flat-primary"
                size="sm"
                :to="'/sale_orderforms/view/' + data.item.order_number"
                :disabled="data.item.id_com_user_manager !== userData.id"
              >
                {{ data.item.order_number }}
              </b-button>
            </template>
            <template v-else>
              <b-button
                variant="flat-primary"
                size="sm"
                :to="'/sale_orderforms/view/' + data.item.order_number"
              >
                {{ data.item.order_number }}
              </b-button>
            </template>
          </template>
          <template #cell(customer_name)="data">
            {{ data.item.customer_name }}
            <div v-if="data.item.company_name">
              <small class="text-warning font-small-1">{{ data.item.company_name }}</small>
            </div>
          </template>
          <template #cell(brand)="data">
            {{ data.item.brand }} {{ data.item.model }}
            <div class="text-danger font-small-1">
              {{ data.item.chasis }}
            </div>
          </template>
          <template #cell(status)="data">
            <div>
              <b-badge :variant="data.item.status === '1' ? 'success' : 'warning'">
                <div v-if="data.item.status === '0'">
                  Onay Bekliyor
                </div>
                <div v-if="data.item.status === '1'">
                  Onaylandı
                </div>
                <div v-if="data.item.status === '2'">
                  Reddedildi
                </div>
                <div v-if="data.item.status === '3'">
                  Hazırlanıyor
                </div>
                <div v-if="data.item.status === '4'">
                  Aksesuar İçin Gönderildi
                </div>
              </b-badge>
            </div>
          </template>
          <template
            #cell(id_candeniz)="data"
          >
            <div class="text-center">
              <b-button
                v-b-modal.candeniz-modal
                :variant="!data.item.id_candeniz ? 'flat-danger' : 'flat-success'"
                size="sm"
                :disabled="!data.item.id_candeniz ? true : false"
                @click="getCandeniz(data.item.order_number)"
              >
                <span v-text="data.item.id_candeniz ? 'Gönderildi' : 'Gönderilmedi' " />
              </b-button>
            </div>
          </template>
          <template #cell(invoice_date)="data">
            {{ data.item.invoice_date? moment(data.item.invoice_date).format('DD.MM.YYYY') : '-' }}
          </template>
        </b-table>
        <b-card-footer class="d-flex align-items-center justify-content-between">
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
          <div class="font-small-2 text-muted">
            Toplam {{ dataCount | toNumber }} adet satış föyü bulundu.
          </div>
        </b-card-footer>
      </b-card>
    </b-col>
    <candeniz-modal />
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BBadge,
} from 'bootstrap-vue'
import PublicFilter from '@/views/Sales/Sale_orderforms/Index/PublicFilter.vue'
import Loading from '@/layouts/components/Loading.vue'
import CandenizModal from '@/views/Sales/Sale_orderforms/components/CandenizModal.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BBadge,
    PublicFilter,
    Loading,
    CandenizModal,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      currentPage: 1,
      perPage: 25,
      search: '',
      fields: [
        {
          key: 'order_number',
          label: 'No',
          thStyle: { width: '70px', verticalAlign: 'middle', padding: '10px' },
        },
        {
          key: 'customer_name',
          label: 'Müşteri',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'brand',
          label: 'Araç Bilgisi',
          thStyle: { verticalAlign: 'middle', width: '200px' },
        },
        {
          key: 'username',
          label: 'Danışman',
          thStyle: { width: '350px', verticalAlign: 'middle' },
        },
        {
          key: 'status',
          label: 'Durum',
          thStyle: { width: '90px', verticalAlign: 'middle' },
        },
        {
          key: 'id_candeniz',
          label: 'Tescil',
          thStyle: { width: '160px', verticalAlign: 'middle', textAlign: 'center' },
        },
        {
          key: 'invoice_date',
          label: 'Fatura Tarihi',
          thStyle: { width: '160px', verticalAlign: 'middle' },
        },
      ],
      dataQuery: {
        select: [
          'com_sale_orderform.id AS id',
          'com_sale_orderform.order_number AS order_number',
          'com_customer.name AS customer_name',
          'com_customer.company_name AS company_name',
          'com_brand.name AS brand',
          'com_model.title AS model',
          'com_stock.chasis AS chasis',
          'com_stock.engine AS engine',
          '_mssql_aracsatis.invoice_date AS invoice_date',
          'com_user.name AS username',
          'com_user.id_com_user_manager as id_com_user_manager',
          'com_sale_orderform.id_com_user AS id_com_user',
          'com_sale_orderform.status AS status',
          'com_sale_orderform.pool_status AS pool_status',
          '_json_candeniz.id AS id_candeniz',
        ],
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    filterData() {
      return this.$store.getters['saleOrderforms/getFilterData']
    },
    saleOrderFormLoading() {
      return this.$store.getters['saleOrderforms/getSaleOrderFormLoading']
    },
    dataList() {
      return this.$store.getters['saleOrderforms/getSaleOrderForms']
    },
    dataCount() {
      return this.$store.getters['saleOrderforms/getSaleOrderFormsCount']
    },
    quickSearch() {
      return this.$store.getters['saleOrderforms/getQuickSearch']
    },
    downloadExcel() {
      const urlParams = []
      if (this.filterData.sdate) {
        urlParams.push(`sdate=${this.filterData.sdate}`)
      }
      if (this.filterData.edate) {
        urlParams.push(`edate=${this.filterData.edate}`)
      }
      if (this.filterData.id_com_brand) {
        urlParams.push(`id_com_brand=${this.filterData.id_com_brand}`)
      }
      if (this.filterData.status) {
        urlParams.push(`status=${this.filterData.status}`)
      }
      if (this.filterData.id_com_location) {
        urlParams.push(`id_com_location=${this.filterData.id_com_location}`)
      }
      if (this.filterData.id_com_user) {
        urlParams.push(`id_com_user=${this.filterData.id_com_user}`)
      }
      const params = urlParams.join('&')

      let url = `${this.$store.state.app.baseURL}/exports/data/Sale_export/excel?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    searchData() {
      if (this.quickSearch.keyword.length > 0) {
        this.dataQuery.or_like = {
          'com_sale_orderform.order_number': this.quickSearch.keyword,
          'com_customer.name': this.quickSearch.keyword,
          'com_customer.company_name': this.quickSearch.keyword,
          'com_stock.chasis': this.quickSearch.keyword,
        }
        this.getDataList()
      }
    },
    resetSearch() {
      this.dataQuery.or_like = {}
      this.dataQuery.where = {}
      this.getDataList()
    },
    getFilterData(params) {
      const where = {}
      if (params.sdate) {
        where['com_sale_orderform.created >='] = params.sdate
      }
      if (params.edate) {
        where['com_sale_orderform.created <='] = params.edate
      }
      if (params.id_com_brand) {
        where['com_sale_orderform.id_com_brand'] = params.id_com_brand
      }
      if (params.id_com_location) {
        where['com_user.id_com_location'] = params.id_com_location
      }
      if (params.id_com_user) {
        where['com_sale_orderform.id_com_user'] = params.id_com_user
      }
      if (params.status !== null) {
        where['com_sale_orderform.status'] = params.status
      }
      this.dataQuery.where = where
      this.getDataList()
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      if (!this.dataQuery.where) {
        if (this.userData.id_com_department === '2') {
          if (['2', '3', '4'].includes(this.userData.id_com_user_type)) {
            this.dataQuery.where = {
              'com_sale_orderform.id_com_brand': this.userData.id_com_brand,
            }
            this.filterData.id_com_brand = this.userData.id_com_brand
          }
          if (['5'].includes(this.userData.id_com_user_type)) {
            this.dataQuery.where = {
              'com_sale_orderform.id_com_brand': this.userData.id_com_brand,
              'com_sale_orderform.id_com_user': this.userData.id,
            }
            this.filterData.id_com_brand = this.userData.id_com_brand
            this.filterData.id_com_user = this.userData.id
          }
        }
      }
      this.$store.dispatch('saleOrderforms/saleOrderFormsList', this.dataQuery)
    },
    getCandeniz(orderNumber) {
      this.$store.dispatch('saleOrderforms/candenizData', orderNumber)
    },
  },
}
</script>

<style scoped>

</style>
