<template>
  <b-modal
    id="candeniz-modal"
    ok-only
    ok-title="Kapat"
    modal-class="modal-primary"
    centered
    title="Plaka Durum Takip"
    size="lg"
  >
    <b-table-simple bordered>
      <b-tbody>
        <b-tr>
          <b-td width="180">
            <strong>Sözleşme No:</strong>
          </b-td>
          <b-td>
            {{ modalData.sozlesmeno }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>
            <strong>Müşteri Adı:</strong>
          </b-td>
          <b-td>
            {{ modalData.musteriadi }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>
            <strong>Tarih Saat:</strong>
          </b-td>
          <b-td>
            {{ moment(modalData.tarihsaat).format('LL') }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>
            <strong>Lokasyon:</strong>
          </b-td>
          <b-td>
            {{ modalData.firma }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>
            <strong>Açıklama:</strong>
          </b-td>
          <b-td>
            {{ modalData.aciklama }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>
            <strong>Plaka:</strong>
          </b-td>
          <b-td>
            {{ modalData.plaka }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>
            <strong>Teslimat:</strong>
          </b-td>
          <b-td>
            {{ modalData.teslimat }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-modal>
</template>

<script>
import {
  BModal, BTableSimple, BTr, BTd, BTbody,
} from 'bootstrap-vue'

export default {
  name: 'CandenizModal',
  components: {
    BModal,
    BTableSimple,
    BTr,
    BTd,
    BTbody,
  },
  computed: {
    modalData() {
      return this.$store.getters['saleOrderforms/getCandeniz']
    },
  },
}
</script>

<style scoped>

</style>
