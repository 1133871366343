<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group
          label="Hızlı Arama"
          label-for="keyword"
        >
          <b-input-group>
            <b-form-input
              id="keyword"
              v-model="quickSearch.keyword"
              placeholder="Sipariş No, Müşteri Adı, Şase No..."
              @keydown.enter="searchAction"
            />
            <b-input-group-append>
              <b-button
                v-if="quickSearch.keyword"
                variant="warning"
                @click="resetAction"
              >
                <FeatherIcon icon="XCircleIcon" />
              </b-button>
              <b-button
                variant="primary"
                @click="searchAction"
              ><FeatherIcon icon="SearchIcon" /></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="auto">
        <b-form-checkbox
          v-model="detailSearch"
          class="mt-2"
          name="check-button"
          style="padding-top: 5px"
          switch
          inline
        >
          Detaylı Arama
        </b-form-checkbox>
      </b-col>
      <b-col cols="auto">
        <b-button
          :href="downloadExcel"
          target="_blank"
          variant="success"
          class="mr-1 mt-2"
        >
          <FeatherIcon icon="FileTextIcon" />
          Excel Aktar
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="detailSearch">
      <b-col
        xs="12"
        md="4"
      >
        <b-form-group
          label="Lokasyon"
          label-for="id_com_location"
        >
          <v-select
            id="id_com_location"
            v-model="filterData.id_com_location"
            placeholder="Lokasyon"
            :options="locations"
            label="title"
            :reduce="locations => locations.id"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="4"
      >
        <b-form-group
          label="Marka"
          label-for="id_com_brand"
        >
          <v-select
            id="id_com_brand"
            v-model="filterData.id_com_brand"
            placeholder="Marka"
            :options="brands"
            label="title"
            :reduce="brand => brand.id"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="4"
      >
        <b-form-group
          label="Danışman"
          label-for="id_com_user"
        >
          <v-select
            id="id_com_user"
            v-model="filterData.id_com_user"
            placeholder="Danışman"
            :options="users"
            label="title"
            :reduce="user => user.id"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="4"
      >
        <b-form-group
          label="Durum"
          label-for="status"
        >
          <v-select
            id="status"
            v-model="filterData.status"
            placeholder="Durum"
            :options="statuses"
            label="title"
            :reduce="status => status.id"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="4"
      >
        <b-form-group
          label="Başlangıç Tarihi"
          label-for="sdate"
        >
          <b-form-datepicker
            id="sdate"
            v-model="filterData.sdate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="4"
      >
        <b-form-group
          label="Bitiş Tarihi"
          label-for="edate"
        >
          <b-form-datepicker
            id="edate"
            v-model="filterData.edate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-button
          variant="primary"
          @click="filterAction"
        >
          <FeatherIcon icon="FilterIcon" />
          Filtrele
        </b-button>
        <b-button
          v-if="filterData.id_com_location !== null || filterData.id_com_brand !== null || filterData.id_com_user !== null || filterData.status !== null || filterData.sdate !== null || filterData.edate !== null"
          class="ml-1"
          variant="warning"
          @click="resetAction"
        >
          <FeatherIcon icon="XCircleIcon" />
          Sıfırla
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BFormCheckbox, BInputGroup, BInputGroupAppend, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'PublicFilter',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    vSelect,
  },
  props: {
    actionMethods: {
      type: Function,
      required: true,
    },
    actionFilter: {
      type: Function,
      required: true,
    },
    resetFilter: {
      type: Function,
      required: true,
    },
    downloadExcel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      keyword: null,
      detailSearch: false,
      statuses: [
        {
          id: 0,
          title: 'Onay Bekliyor',
        },
        {
          id: 1,
          title: 'Onaylandı',
        },
        {
          id: 3,
          title: 'Hazırlanıyor',
        },
        {
          id: 2,
          title: 'Reddedildi',
        },
      ],
    }
  },
  computed: {
    quickSearch() {
      return this.$store.getters['saleOrderforms/getQuickSearch']
    },
    filterData() {
      return this.$store.getters['saleOrderforms/getFilterData']
    },
    locations() {
      return this.$store.getters['locations/getLocations']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
  },
  created() {
    this.getLocations()
    this.getBrands()
    this.getUsers()
  },
  methods: {
    getLocations() {
      this.$store.dispatch('locations/locationsList', {
        select: ['com_location.id AS id', 'com_location.title AS title'],
      })
    },
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS title'],
        where: {
          'com_brand.salestatus': 1,
        },
      })
    },
    getUsers() {
      this.$store.dispatch('users/usersList', {
        select: ['com_user.id AS id', 'com_user.name AS title'],
        where: {
          'com_user.status': 1,
          'com_user.id_com_department': 2,
        },
      })
    },
    searchAction() {
      this.actionMethods()
    },
    filterAction() {
      this.actionFilter(this.filterData)
    },
    resetAction() {
      this.quickSearch.keyword = null
      this.filterData = {
        id_com_location: null,
        id_com_brand: null,
        id_com_user: null,
        status: null,
        sdate: null,
        edate: null,
      }
      this.detailSearch = false
      this.resetFilter()
    },
  },
}
</script>
<style scoped></style>
<style lang="scss">

@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
